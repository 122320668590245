export default{
    title:"Inicio",
    store:"Tienda",
    services:"Servicios",
    filters:"Filtro",
    menu:"Menú",
    contact_us:"Contáctenos",
    about_us:"¿Quiénes somos?",
    shopping_card_info:"Gift Card",
    links:"Enlaces",
    contact:"Contacto",
    copyright:"Copyright 2023. Ingenius S.U.R.L.",
    price:"Precio",
    brands:"Marcas",
    count_product:"{product} productos",
    select_local: "Local de recogida"
}