export default {  
    title: "Settings",  
    generals: "General",  
    customization: "Customization",  
    points_of_sale: "Points of Sale",  
    notifications: "Notifications",  
    misc: "Miscellaneous",  
    information: "Information",  
    pay_forms: "Payment Methods",  
    return_policy: "Return Policy",  
    warranty_policy: "Warranty Policy",  
    shipment_policy: "Shipping Policy",  
    frequent_asks: "Frequently Asked Questions",  
    terms_conditions: "Terms and Conditions",  
    about_us: "Who We Are?",  
    shipment: "Shipping",  
    related_products: "Related Products",  
    shopping_cards: "Shopping Cart Page",  
    from: "From",  
    to: "To",  
    form: "Form",
    coins: "Coins"  
}