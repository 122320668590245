export default{
    title:"",
    show:"",
    checkout:"",
    empty:"",
    shipment_data:"",
    payment_data:"",
    buyer_data:"",
    add_beneficiary:"",
    delete_beneficiary_question:"",
    no_beneficiaries:"",
    beneficiary:"",
    second_beneficiary:"",
    alternative_beneficiary:"",
    shipment_method:"",
    home_shipping_address_label:"",
    local_pickup_address_label:"",
    beneficiary_alternative:"",
    messages_payer:"",
    messages_beneficiary:"",
    messages_beneficiary_alternative:"",
    no_beneficiary_selected:"",
    no_alternative_beneficiary_selected:""
}