export default{
    title:"Empresas",
    create:"Crear Empresa",
    edit:"Editar Empresa",
    disabled:"Deshabilitar",
    enabled:"Habilitado",
    statuses: {
        pending: "Pendiente",
        approved: 'Aprobada',
        rejected: 'Rechazada',
        cancelled: 'Cancelada'
    },
    changed_by: "Cambiado por",
    approved_by: "Aprobado por",
    rejected_by: "Rechazado por",
    cancelled_by: "Cancelado por",
    details: "Detalles de la Empresa",
    lawsuits: "Últimas Solicitudes",
    lawsuits_title: "Datos de la Empresa",
    isCompany: "Pertenece a una empresa",
    isNoCompany: "¿Pertenece a una empresa?",
    statusLawsuit: "Estado de la solicitud: "
}