export default {  
    title: "Report",  
    for_sale: "Sales",  
    for_inventory: "Inventory",  
    for_point_sale: "Pickup Location",  
    for_general_sale: "General Sale",  
    for_wholesale: "Wholesale Sales",  
    for_retail: "Retail Sales",  
    orders: "Orders",  
    movements: "Movements",  
    sales_accounting: "Sales for Accounting",  
    sold_product: "Sold Products"  
}