export default{
    title: "Banners",
    create: "Crear banner",
    edit: "Editar",
    delete: "Eliminar",
    section: "URL sección del banner",
    title_text: "Título del banner",
    title_button: "Texto del botón",
    url: "URL de redirección",
    active_web: "Visible en modo web",
    active_movile: "Visible en modo móvil",
    button_color: "Color del botón",
    button_text_color: "Color de texto del botón",
    button_position: "Posición del botón",
    top_center: "Arriba en el centro",
    top_left: "Arriba a la izquierda",
    top_right: "Arriba a la derecha",
    center_right: "En el centro a la derecha",
    center_left: "En el centro a la izquierda",
    cente: "En el centro",
    bottom_left: "Abajo a la izquierda",
    bottom_center: "Abajo en el centro",
    bottom_right: "Abajo a la derecha",
    banner_data: "Datos del banner",
    buttom_data: "Datos del botón",
    button_border_color: "Color del borde",
    empty_for_transparent: "Dejar en blanco para borde transparente",
    button_border_width: "Ancho del border",
    in_pixels: "En pixeles"
}