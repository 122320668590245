export default{
    delete_confirmation: "Confirm deletion",
    delete_confirmation_msg: "This action cannot be undone. Are you sure?",
    forgot_password: "Forgot your password?",
    forgot_password_msg: "Forgot your password? No problem. Just let us know your email address and we will send you a link to reset your password.",
    already_registered: "Already have an account?",
    no_have_account: "Don’t have an account?",
    confirm_password: "This is a secure area of the application. Please confirm your password before continuing.",
    email_unverified: "Your email address is not verified.",
    verification_resent: "A new verification link has been sent to your email address.",
    out_stock: "Out of stock",
    in_stock: "In stock",
    available_stock: "Only ",
    commas_phones: "Separated by commas",
    admin_notifications: "Notify events to administrators",
    auto_sku: "Automatically generate product SKU",
    enable_branch_offices: "Use multiple points of sale",
    pay_forms_subtitle: "Configure the payment methods to be used on the platform.",
    no_pay_forms: "No payment methods available.",
    payment_timeout: "Time available for users to pay for their orders.",
    created_successfully: "Created successfully.",
    not_more_20: "Please use fewer than 20 characters",
    not_more_50: "Please use fewer than 50 characters",
    failed_creating_invoice: "Invoice creation failed.",
    invoice_already_exists: "An invoice already exists.",
    external_redirect: "You will now be redirected to an external site.",
    pending_to_pay: "Pending payment",
    unavailable: "Unavailable",
    available: "Available",
    leave_message: "Leave us a message",
    location_help: "Go to Google maps, find the desired location, share it, and copy the provided HTML code.",
    analytics_message: "Get insights into how people find your site and how to improve and optimize your content.",
    wholesale_prepayment: "Prepayment for wholesale sales",
    change_status_success: "Status changed successfully.",
    change_status_error: "Failed to change status.",
    need_for_distribution: "This order needs to be dispatched.",
    order_paid: "Payment successfully accepted.",
    error_on_shipment_calculation: "An error occurred while calculating the shipping cost.",
    not_more_12: "Please use fewer than 12 characters",
    not_more_30: "Please use fewer than 30 characters",
    manual_relateds: "This selection will only be used if the system configuration for related products is 'Manual'.",
    recharge_paid: "Payment successfully accepted.",
    most_selled_roduct: "Best sellers products",
    tooltip_file: "You can only upload files in .pdf or .docx format.",
    tooltip_file_responsive: "Only one file in .pdf or .docx format.",
    tooltip_image: " Only upload images in .png, .jpg, .webp, .avif, or .jiff format.",
    galery_image: "Add images",
    stock_product: "Minimum quantity of the product to be displayed",
    notification_user: "Notify orders placed in the system",
    notification_stock: "Products without low availability configured will start at 1/4 of their stock availability.",
    validation_phone: "The phone number does not match its format.",
    coordination_helps: "Longitude / Latitude e.g.: -82.42189014665645 / 23.11644723797522",
    need_map_selection: "You need to select your location on the map.",
    need_beneficiary: "You need to select a beneficiary.",
    this_field: "The field ",
    field_required: " is required",
    need_province: "The purchase beneficiary requires a province for shipping.",
    order_not_paid: "Payment cannot be made.",
    empty_fields: "There are empty fields.",
    new_quantity: "New quantity",
    products_beyond_max: "These products exceed the maximum quantity for home delivery; you can continue your order and pick it up at our warehouse.",
    max: "Max.",
    current: "Current",
    search_all_store: "Search the entire store",
    order_created: "Your order has been successfully created.",
    go_to_order: "View order",
    go_to_store: "Go to store",
    need_new_beneficiary: "You need to add and select a beneficiary.",
    use_store_address_as_pickup_option: "Use store address as pickup option.",
    notification_email_manual_invoice: "Notify by email",
    notification_text_description_email_manual_invoice: "A confirmation email will be sent to administrators, customer, and beneficiary.",
    count_not_disponibility: "Quantities not allowed",
    disponibility_formulae: "It is calculated by subtracting from the quantity available for sale, the quantity in carts, and the quantity in orders with status {statuses}.",
    discount_in_status: "Discount from inventory in status",
    change_visible: "Change visibility status",
    shipment_unit_range_format: "Must start with a range formatted [0 - X] and end with one [Y - ∞]",
    leave_blank_for_infinity: "Leave blank for ∞",
    error_quantity: "Quantity not allowed",
    no_data: "No data",
    rating_product: "Rate product: {name}",
    time_order: "Times must be entered in ascending order, from lowest to highest",
    notification_shopping: "Purchase notifications",
    send_email_beneficiary: "Enable email sending to purchase beneficiaries",
    need_municipalit: "The purchase beneficiary requires a municipality for shipping.",
    need_address: "The purchase beneficiary requires a shipping address.",
    all_brands:"All brands",
    download_product_specification_sheet: "Dowload product specification sheet",
    loading_cart: "Loading shopcart",
    product_not_available: "Product not available in the actual location",
    reason_changed: "Reason for status \"{status}\" (Maximum 250 characters)",
    reason: "Reason",
    reason_of_rejection: "Reason of rejection",
    no_reason: "No reason",
    error_send_new_lawsuit: "Error sending lawsuit.",
    error_update_profile: "Error updating profile.",
    error_field_date_init: "The start date is required.",
    error_field_date_end: "The end date is required.",
    error_field_products_ids: "You must select almost 1product",
    delete_offer: "Offer successfully deleted",
    error_delete_offer: "Could not delete offer",
    delete_user: "User successfully deleted",
    error_delete_user: "Could not delete user",
    delete_offers: "Offers successfully deleted",
    error_delete_offers: "Could not delete offers",
    error_create_user: "Could not create user",
    error_update_user: "Could not update user",
    images_limit:"Images must not exceed 300kb.",
    select_related_products:"Selected related products",
    selected_categories:"When choosing categories the banner will appear only in them",
}