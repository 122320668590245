import messages from './lang'

export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'es',
    fallbackLocale: 'es',
    messages: messages,
    detectBrowserLanguage: {
        useCookie: true, // Habilita el uso de cookies para almacenar el idioma
        cookieKey: 'i18n_redirected', // Nombre de la cookie
        alwaysRedirect: false,
        fallbackLocale: 'es',
      }
}))
