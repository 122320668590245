export default{
        title: "Acciones",
        details: "Detalles",
        edit: "Editar",
        delete: "Eliminar",
        enabled: "Habilitar",
        disabled: "Deshabilitar",
        cancel: "Cancelar",
        ok: "Aceptar",
        add: "Añadir",
        search: "Buscar",
        update: "Actualizar",
        login: "Iniciar sesión",
        login_with_google: "Acceder con Google",
        register: "Registrarse",
        logout: "Cerrar sesión",
        confirm: "Confirmar",
        send_email: "Enviar correo electrónico",
        reset_password: "Restablecer contraseña",
        resend_verification: "Reenviar enlace de verificación",
        delete_account: "Eliminar cuenta",
        save: "Guardar",
        resend_verification_email: "Haga clic aquí para volver a enviar el correo electrónico de verificación.",
        show: "Ver detalles",
        show_variations: "Ver variaciones",
        show_options: "Ver opciones",
        checkout: "Verificar",
        complete: "Completar",
        continue: "Continuar",
        finish: "Finalizar",
        back: "Atrás",
        generate: "Generar",
        close: "Cerrar",
        reset: "Reiniciar",
        pay_with: "Pagar con {name}",
        create_invoice: "Crear factura",
        export: "Exportar",
        pay: "Pagar",
        request_info: "Solicitar información",
        send: "Enviar",
        change_status: "Cambiar estado",
        change: "Cambiar",
        distribute_order: "Despachar orden",
        read_more: "Leer más...",
        select_pickup_address: "Dirección de recogida",
        buy: "Comprar",
        show_orderable: "Mostrar Pedido",
        see_more_details: "Ver detalles",
        use_beneficiary_data: "Usar datos del beneficiario",
        dispatch_order : "Orden de despacho",
        pay_invoice: "Marcar factura como pagada",
        see_more: "Ver más...",
        use_variation: "Usar variación",
        new_schedule: "Crear conjunto de horario",
        print:"Imprimir",
        approve: "Aprobar",
        decline: "Rechazar",
        share: "Compartir",
        select_all: "Selec. todo",
        deselect_all: "Deselec. todo",
        search_product:"Buscar productos",
        shop_by_category:"Comprar por categoría",
        see_products:"Ver detalles del producto",
        insert_address: "Agregar dirección",
        edit_address: "Editar dirección",
        newLawsuit: "Nueva solicitud"

}