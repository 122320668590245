export default{
    all_status: "Todos los estados",
      all_coins: "Todos",
      countBuy: "Cantidad de compra",
      sku: "SKU",
      name: "Nombre",
      lastname: "Apellidos",
      email: "Correo electrónico",
      password: "Contraseña",
      current_password: "Contraseña actual",
      password_confirmation: "Confirmar contraseña",
      created_at: "Fecha de creación",
      role: "Rol",
      no_role: "Sin Rol",
      description: "Descripción",
      short_description: "Descripción corta",
      instructions: "Instrucciones",
      active: "Activo",
      initial: "Inicial",
      final: "Final",
      variant: "Variante",
      price: "Precio",
      price_up: "Precio: De menor a mayor",
      price_down: "Precio: De mayor a menor",
      stock: "Disponibilidad",
      stock_real: "Disponibilidad Real",
      stock_current: "Disponibilidad Actual",
      images: "Imágenes",
      image: "Imagen",
      galery_image: "Galería de Imágenes",
      phone: "Teléfono",
      company_name: "Nombre de la empresa",
      company_NIT: "NIT de la empresa",
      company_phone: "Teléfono de la empresa",
      company_address: "Dirección de la empresa",
      phones: "Teléfonos",
      address: "Dirección",
      data: "Datos",
      parent: "Padre",
      category: "Categoría",
      subtotal: "Subtotal",
      total: "Total",
      pay_form: "Forma de pago",
      note: "Nota",
      quantity: "Cantidad",
      current_quantity: "Para la venta",
      quantities: "Cantidades",
      status: "Estado",
      latest: "Últimos",
      short_name: "Nombre corto",
      symbol: "Símbolo",
      position: "Posición",
      is_main: "Principal",
      categories: "Categorías",
      regular_price: "Precio regular",
      sale_price: "Precio de venta",
      wholesale_price: "Precio mayorista",
      min_quantity: "Cantidad mínima",
      virtual: "Virtual",
      downloadable: "Descargable",
      wholesaler: "Mayorista",
      retail: "Minorista",
      bookable: "Reservable",
      visible: "Visible",
      value: "Valor",
      real_quantity: "Cantidad real",
      initial_availability: "Cantidad inicial",
      record_availability:"Cantidad histórica",
      reserve: "Reserva",
      reserves: "Reservas",
      wholesale_quantities: "Cantidades mayoristas",
      real_reserve_availability: "Reserva real",
      wholesale_quantity: "Cantidad mayorista",
      current_wholesale_quantity: "Cantidad mayorista actual",
      real_wholesale_quantity_availability: "Cantidad mayorista real",
      real_retail_quantity_availability: "Cantidad minorista real",
      initial_wholesale_quantity_availability: "Cantidad mayorista inicial",
      date: "Fecha",
      coin: "Moneda",
      exchange_rate: "Tasa de cambio",
      information: "Información",
      logo: "Logo",
      user: "Usuario",
      debit_card: "Tarjeta de débito",
      confirmation_phone: "Teléfono de confirmación",
      payment_timeout: "Tiempo de espera de los pagos (mn)",
      from: "Origen",
      to: "Destino",
      team_name: "Nombre del equipo de soporte",
      prepayment_percentage: "Porcentaje de prepago",
      number: "Número",
      client_id: "Client Id",
      client_secret: "Client Secret",
      cancelled: "Cancelado",
      date_payment: "Fecha de compra",
      date_sale: "Fecha de la venta",
      type: "Tipo",
      available: "Disponible",
      duration: "Duración",
      from_commercial: "Comercial remitente",
      to_commercial: "Comercial destinatario",
      in_offer: "En oferta",
      agree: "Si, estoy de acuerdo",
      unit: "Unidad de medida",
      message: "Mensaje",
      enable: "Habilitado",
      location: "Ubicación (html)",
      price_per_unity: "Precio por unidad",
      to_pay: "A pagar",
      pending: "Pendiente",
      prepayment_pay: "Pago adelantado",
      total_final: "Importe final",
      import_total: "Importe total",
      import_all_sale: "Importe total de las ventas",
      only_wholesaler: "Solo mayorista",
      import: "Importe",
      prepayment_import: "Importe adelantado",
      product: "Producto",
      import_product: "Importe del producto",
      import_for_customer: "Importe total del cliente",
      sale_id: "# Venta",
      invoices_num: "# Factura",
      details: "Detallado",
      stock_wholesale: "Disponibilidad Mayorista",
      order_id: "Identificador del Pedido",
      paid: "Pagado",
      shipment: "Envío",
      shipment_price: "Costo de envío",
      date_born: "Fecha de nacimiento",
      shipment_distance: "Distancia del envío",
      title_color: "Color del título",
      title: "Título",
      body: "Cuerpo",
      summary: "Resumen",
      author: "Autor",
      content: "Contenido",
      price_fixed: "Precio fijo",
      paid_for_kilometers: "Precio por kilómetro",
      min_import_for_free_shipping: "Importe mínimo para envío gratuito",
      per_km_strategy_calculation: "Estrategia cálculo de envío en caso de múltiples puntos de venta",
      price_fixed_threshold: "Umbral precio fijo",
      use_for_local_pickup: "¿Usar para recogida local?",
      beneficiary: "Mis beneficiarios",
      shopping_card: "Tarjeta de compra",
      optional_price: "Precio (opcional)",
      code: "Código",
      remaining: "Saldo disponible",
      card_beneficiary: "Beneficiarios",
      amount: "Monto",
      cart_item_timeout: "Tiempo de espera de los elementos en el carrito (mn)",
      file: "Archivo",
      data_sheet: "Ficha técnica",
      upload_file: "Subir archivo",
      secret: "Secret",
      faq: "FAQ",
      ask: "Pregunta",
      coordinates: "Coordenadas",
      is_stock_product: "Mostrar cantidad en stock del producto",
      count_min: "Cantidad mínima",
      time: "Hora",
      low_availability: "Cantidad mínima en stock",
      availability_notification: "Notificación de baja disponibilidad",
      only_base_coin: "Solo moneda base",
      history_count: "Cantidad Histórica",
      coordinates_long: "Longitud",
      coordinates_lat: "Latitud",
      date_init: "Fecha inicial",
      date_end: "Fecha final",
      shopping_cards: "Gift Card",
      province: "Provincia",
      phone_code_country: "+53",
      max_quantity_for_home_shipping: "Cantidad máx. para envío a domicilio (en unidad base)",
      departments: "Departamentos",
      open_label: "Horario de atención",
      open: "Martes a Viernes: 9:00am a 1:00pm - 2:00pm a 5:30pm ",
      open_weekends: "Sábados: 9:00am a 2:00pm",
      tax: "Impuesto (%)",
      tax_value: "Comisión por Forma de Pago",
      priority: "Prioridad",
      outstanding: "Destacados",
      favicon: "Favicon",
      keywords: "Palabras clave",
      warranty: "Garantía",
      time_warranty: "Tiempo de garantía",
      show_warranty: "Mostrar garantía",
      unit_warranty: "Unidad de medida",
      banner_category: "Imágenes del banner para la categoría",
      banner_category_phone: "Imágenes del banner móvil para la categoría",
      client: "Cliente",
      contract : "Contrato",
      almacen: "Almacén",
      shoponline: "Tienda Online",
      shoponlinealmacen: "Almacén Tienda Online",
      reference_client: "Referencia Cliente",
      accounting_identify: "Líneas del pedido/Producto",
      accounting_description: "Líneas del pedido/Descripción",
      accounting_quantity: "Líneas del pedido/Cantidad",
      accounting_unit : "Líneas del pedido/Unidad de medida",
      accounting_price : "Líneas del pedido/Precio de Unidad",
      beneficiary_tittle: "Beneficiario",
      order_summary: "Resumen",
      order_costs: "Importe",
      disponibility_real_time: "Disponibilidad en tiempo real",
      disponibility: "Disponibilidad",
      shop_cart_quantity: "Cantidad en carritos",
      locations: "Mostrar en",
      mobile_image: "Imagen móvil",
      activated: "Activado",
      not_activated: "Desactivado",
      orders_quantity: "Cantidad en pedidos {statuses}",
      time_period: "Periodo de tiempo",
      since: "Desde",
      until: "Hasta",
      clear: "Limpiar",
      home_shipping: "Entrega a domicilio",
      shipment_unit_range: "Rangos por unidad de envío",
      min: "Min",
      max: "Max",
      current_strategy: "Estrategia de envío",
      shipment_unit: "Unidad de envío",
      shipment_provinces: "Provincias de envío",
      use_free_shipping_threshold: "Importe mínimo para envio gratuito",
      disponibility_from_store: "Usar inventario de",
      second_beneficiary_title: "Beneficiario alternativo",
      seconds_beneficiary_title: "Beneficiarios alternativos",
      no_alternative_beneficiary: "No se registró un beneficiario alternativo",
      orderable_status: "Estado del pedido",
      sale_type: "Venta",
      reservation_type: "Mayorista",
      all: "Todos",
      paid_at: "Pagado el",
      payment_status: "Estado de pago",
      schedule_management: "Gestión de horario",
      send_email_beneficiary: "Correo a beneficiario de compra",
      municipality:"Municipio",
      prices: "Precios",
      generals: "Generales",
      inventory: "Inventario",
      public_key: "Llave pública",
      secret_key: "Llave secreta",
      free_shipping_message: "Mensaje a mostrar en el carrito cuando envío gratuito",
      count_products:"Cantidad de productos",
      search_country: "Seleccione el país",
      ratings: "Valoraciones ( {number} )",
      order_by: "Ordenar por:",
      municipalities: "Municipios",
      main_street: "Calle principal",
      first_street: "Entre calle 1",
      second_street: "Entre calle 2",
      house_or_apartment: "No. de casa, No. apt. y edif., etc",
      point_of_reference: "Punto de referencia",
      contacted: "Contactado",
      products:"Productos",
      point_of_sale:"Punto de venta",
      company_images: 'Imagenes de la Empresa',
      add_image: 'Imágenes de referencia',
      add_company_image: 'Imágenes de documento identificativo de la empresa',
      add_image_hint: 'Añadir máximo de  {count} imagen |Añadir máximo de {count} imágenes',
      brands:"Marcas",
      fullname: "Nombre Completo",
      reason: "Motivo",
      changed_at: "Fecha",
      discount_percentage: "Descuento (%)",
      offer: "Oferta",
      discount_price: "Precio con la Rebaja",
      description_details:"Descripción detallada"
}
