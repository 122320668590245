import lang from './lang'
import attributes from './attributes'
import categories from './categories'
import dashboard from './dashboard'
import delete_account from './delete_account'
import home from './home'
import login from './login'
import nav from './nav'
import orderStatuses from './orderStatuses'
import payForms from './payForms'
import products from './products'
import profile from './profile'
import profile_info from './profile_info'
import register from './register'
import roles from './roles'
import update_password from './update_password'
import users from './users'
import verify_email from './verify_email'
import attribute_terms from './attribute_terms'
import variations from './variations'
import inventory from './inventory'
import distribution from './distribution'
import validation_review from './validation_review'
import services from './services'
import offers from './offers'
import orders from './orders'
import invoices from './invoices'
import cartItems from './cartItems'
import coins from './coins'
import exchange_rates from './exchange_rates'
import point_of_sales from './point_of_sales'
import analytics from './analytics'
import contacts from './contacts'
import reservations from './reservations'
import settings from './settings'
import fields from './fields'
import social from './social'
import beneficiary_of_shipping from './beneficiary_of_shipping'
import actions from './actions'
import messages from './messages'
import validation from './validation'
import sale from './sale'
import store from './store'
import en from './en'
import es from './es'
import tooltip from './tooltip'
import companies from './companies'
import _export from './export'
import report from './report'
import customer from './customer'
import mail from './mail'
import shipping from './shipping'
import misc from './misc'
import discounts from './discounts'
import banners from './banners'

export default {
    nav:nav,
    dashboard:dashboard,
    login:login,
    register:register,
    verify_email:verify_email,
    profile:profile,
    profile_info:profile_info,
    update_password:update_password,
    delete_account:delete_account,
    home:home,
    users:users,
    roles:roles,
    attributes:attributes,
    categories:categories,
    payForms:payForms,
    orderStatuses:orderStatuses,
    products:products,
    lang:lang,
    attribute_terms:attribute_terms,
    variations:variations,
    inventory:inventory,
    distribution:distribution,
    validation_review:validation_review,
    services:services,
    offers:offers,
    orders:orders,
    invoices:invoices,
    cartItems:cartItems,
    coins:coins,
    exchange_rates:exchange_rates,
    point_of_sales:point_of_sales,
    analytics:analytics,
    contacts:contacts,
    reservations:reservations,
    settings:settings,
    fields:fields,
    social:social,
    beneficiary_of_shipping:beneficiary_of_shipping,
    actions:actions,
    messages:messages,
    validation:validation,
    sale:sale,
    store: store,
    es:es,
    en:en,
    tooltip:tooltip,
    companies:companies,
    export:_export,
    report:report,
    customer:customer,
    mail:mail,
    shipping:shipping,
    misc:misc,
    discounts:discounts,
    banners:banners
}
