export default{
    name: "Nombre",
    last_name: "Apellido",
    phone: "Teléfono",
    address: "Dirección",
    title: "Beneficiario de envío",
    own: "Soy el beneficiario",
    new: "Nuevo beneficiario",
    edit: "Editar beneficiario",
    buy: "Datos del envío",
    num_house: "Número de la casa",
    num_apartment: "Número del apartamento",
    date: "Datos del beneficiario",
    email: "Correo electrónico",
    add_card: "Asignar Tarjeta",
    pick_up: "Datos de la recogida",
    pick_up_address: "Dirección de la recogida",
    beneficiary_buy:"Beneficiario de compra",
    own_buy:"Es el comprador",
    check:"Incorporar beneficiario",
    ci: "Carnet de identidad",
    second_check: "Incorporar segundo beneficiario",
    shipping_address: "Dirección de envío",
    no_address: "Aún no ha agregado una dirección de envío",
    select_province_municipality: "Seleccione una provincia y un municipio",
    select_province: "Seleccione una provincia",
    select_municipality: "Seleccione un municipio",
    verify_map_selection: "Solo ha introducido la calle principal. ¿Está seguro de la ubicación en el mapa?."
}