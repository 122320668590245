export default{
    title:"Products",
    list:"List of products",
    create:"Create product",
    edit:"Update product",
    related:"Related products",
    general:"General",
    error_quantity:"Quantity not allowed",
    type:{
        title:"Type of product",
        options:{
            simple:"Simple",
            variable:"Variable"
        }
    },
    in_offer: "In Offert"

}