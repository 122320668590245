export default{
        title: "Actions",
        details: "Details",
        edit: "Edita",
        delete: "Delete",
        enabled: "Enabled",
        disabled: "Disabled",
        cancel: "Cancel",
        ok: "Ok",
        add: "Add",
        search: "Search",
        update: "Update",
        login: "Login",
        login_with_google: "Acceder con Google",
        register: "Regiter",
        logout: "Logout",
        confirm: "Confirm",
        send_email: "Send email",
        reset_password: "Reset password",
        resend_verification: "Reenviar enlace de verificación",
        delete_account: "Delete account",
        save: "Save",
        resend_verification_email: "Haga clic aquí para volver a enviar el correo electrónico de verificación.",
        show: "View details",
        show_variations: "Show variations",
        show_options: "Show options",
        checkout: "Checkout",
        complete: "Complete",
        continue: "Continue",
        finish: "Finish",
        back: "Back",
        generate: "Generate",
        close: "Close",
        reset: "Reset",
        pay_with: "Pay with {name}",
        create_invoice: "Create invoice",
        export: "Export",
        pay: "Pay",
        request_info: "Solicitar información",
        send: "Send",
        change_status: "Cambiar estado",
        change: "Cambiar",
        distribute_order: "Despachar orden",
        read_more: "Leer más...",
        select_pickup_address: "Dirección de recogida",
        buy: "Comprar",
        show_orderable: "Mostrar Pedido",
        see_more_details: "Ver detalles",
        use_beneficiary_data: "Usar datos del beneficiario",
        dispatch_order : "Orden de despacho",
        pay_invoice: "Marcar factura como pagada",
        see_more: "Ver más...",
        use_variation: "Usar variación",
        new_schedule: "Crear conjunto de horario",
        print:"Imprimir",
        approve: "Aprobar",
        decline: "Rechazar",
        share: "Compartir",
        select_all: "Selec. todo",
        deselect_all: "Deselec. todo",
        search_product:"Search products",
        shop_by_category:"Shop by category",
        see_products:"See details product",
        insert_address: "Insert address",
        edit_address: "Edit address",
        newLawsuit: "New lawsuit"
}