export default{
    title: "Reporte",
    for_sale: "Ventas",
    for_inventary: "Inventario",
    for_point_sale: "Local de recogida",
    for_genary_sale: "Venta General",
    for_wholesale: "Ventas mayoristas",
    for_retail: "Ventas minoristas",
    orders: "Pedidos",
    movements: "Movimientos",
    salesaccounting : "Ventas para contabilidad",
    sold_product: "Productos vendidos"
}